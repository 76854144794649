<template>
  <v-card elevation="0" class="pl-5 pb-7">
    <v-card-title class="d-flex align-center mt-5 mb-5">
      <p class="text-body-1 ma-0 font-weight-bold mr-8">Crear usuario</p>
    </v-card-title>
    <v-card elevation="0" class="mb-16 ml-4 d-flex justify-center">
      <v-card class="d-flex flex-column" width="100%" elevation="0">
        <!-- eslint-disable-next-line vue/valid-v-model -->
        <v-form v-model="valid" ref="formCreateUser" lazy-validation>
          <v-container fluid grid-list-md>
            <v-layout row wrap d-flex justify-start>
              <v-flex d-flex flex-column align-start xs12 sm12 md6>
                <v-card class="cont-nv" elevation="0" width="95%" color="white">

                  <v-text-field style="padding: 3px; height: 60px" color="#6200EE" class="input-nv caption" v-model="user.firstName"
                    :rules="[(v) => !!v || 'Este campo es requerido']" placeholder="ej. Juan" required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-account-outline
                      </v-icon>
                      <span class="caption ml-2">Nombre</span>
                    </template>
                  </v-text-field>

                  <v-text-field style="padding: 3px; height: 60px" color="#6200EE" class="input-nv caption" v-model="user.lastName"
                    :rules="[(v) => !!v || 'Este campo es requerido']" placeholder="ej. Perez" required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-account-outline
                      </v-icon>
                      <span class="caption ml-2">Apellidos</span>
                    </template>
                  </v-text-field>

                  <v-text-field style="padding: 3px; height: 60px"  color="#6200EE" class="input-nv caption" v-model="user.loginEmail"
                    :rules="[(v) => !!v || 'Este campo es requerido']" placeholder="ej. juanperez@reclutachat.com"
                    required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-email-outline
                      </v-icon>
                      <span class="caption ml-2">Correo de login</span>
                    </template>
                  </v-text-field>

                  <v-text-field style="padding: 3px; height: 60px" color="#6200EE" class="input-nv caption" v-model="user.contactEmail"
                    :rules="[(v) => !!v || 'Este campo es requerido']" placeholder="ej. juanperez@personal.com"
                    required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-email-outline
                      </v-icon>
                      <span class="caption ml-2">Correo de contacto</span>
                    </template>
                  </v-text-field>

                </v-card>
              </v-flex>

              <v-flex d-flex flex-column align-start xs12 sm12 md6>
                <v-card class="cont-nv" elevation="0" width="95%" color="white">

                  <v-text-field style="padding: 3px; height: 60px" color="#6200EE" class="input-nv caption" v-model="user.password" :rules="passwordRules"
                    placeholder="........" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'" @click:append="show3 = !show3" required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-lock-outline
                      </v-icon>
                      <span class="caption ml-2">Contraseña</span>
                    </template>
                  </v-text-field>

                  <v-text-field style="padding: 3px; height: 60px" color="#6200EE" class="input-nv caption" v-model="user.area"
                    :rules="[(v) => !!v || 'Este campo es requerido']" placeholder="ej. Finanzas" required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-home-modern
                      </v-icon>
                      <span class="caption ml-2">Área Corporativa</span>
                    </template>
                  </v-text-field>

                  <v-text-field style="padding: 3px; height: 60px" color="#6200EE" class="input-nv caption" v-model="user.phone"
                    :rules="[(v) => !!v || 'Este campo es requerido']" placeholder="ej. 5512345678" required>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-phone-outline
                      </v-icon>
                      <span class="caption ml-2">Teléfono</span>
                    </template>
                  </v-text-field>


                  <v-select style="padding: 3px; height: 60px" color="#6200EE" class="input-nv" :items="items" v-model="user.userType" :rules="[
                    (v) => !!v || 'Es necesario seleccionar una opcion',
                  ]" placeholder="ej. Admin" required><template v-slot:label class="caption">
                      <v-icon class="icon-input" small>
                        mdi-account-group-outline
                      </v-icon>
                      <span class="caption ml-2">Tipo de usuario</span>
                    </template>
                  </v-select>

                  <v-select style="padding: 3px; height: 60px" v-if="user.userType === 'Reclutador'" :rules="[(v) => !!v || 'Este campo es requerido']"
                    color="#6200EE" class="input-nv" v-model="user.zones" :items="zones"
                    placeholder="Selecciona la zona" multiple persistent-hint hide-details>
                    <template v-slot:label class="caption">
                      <v-icon class="icon-input" small> mdi-border-all </v-icon>
                      <span class="caption ml-2">Zonas de usuario</span>
                    </template></v-select>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions style="width: 95%" class="mt-3 ma-0 pa-0 d-flex justify-end">
            <v-btn rounded small elevation="0" color="#E3E9EF9E" style="color: #a4a5ad"
              class="pl-8 pr-8 text-capitalize" @click="close">Cerrar</v-btn>
            <v-btn :disabled="!valid" rounded small elevation="0" color="success" class="pl-8 pr-8 mr-5 text-capitalize"
              @click="createUser()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card>

    <div v-if="userStateLoading === true">
      <transition name="modal-fade">
        <div class="modal-backdrop">
          <div class="modal" role="dialog" area-labelledby="modalTitle" aria-describedby="modalDesc">
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">espere un momento...</h4>
              <v-progress-linear color="#6E80F8" indeterminate rounded height="6"></v-progress-linear>
              <!-- <v-progress-circular
              color="white"
              size="40"
              width="6"
              indeterminate
            ></v-progress-circular> -->
            </v-col>
          </div>
        </div>
      </transition>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ENUM from "@/store/enums";

export default {
  data: () => ({
    valid: false,
    show3: false,
    user: {
      firstName: "",
      lastName: "",
      loginEmail: "",
      contactEmail: "",
      password: "",
      userType: "",
      area: "",
      phone: "",
      zones: "",
    },
    e7: [],
    nameRules: [(v) => !!v || "Este campo es requerido"],
    emailRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => /.+@.+\..+/.test(v) || "el email ingresado debe ser valido",
    ],
    passwordRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        (v && v.length >= 8) ||
        "la contraseña debe ser mayor o igual a 8 caracteres alfanumericos",
    ],
    items: ["Admin", "Reclutador"],
  }),
  methods: {
    ...mapActions(["registerUser", "requestZonesByCompanyId"]),
    createUser() {
      const isValid = this.$refs.formCreateUser.validate();
      let zonesIds = [];
      if (isValid) {
        const allZones = this.getZones;
        if (this.user.userType === "Admin") {
          // console.log("entro en el if ")
          for (var i = 0; i < allZones.length; ++i) {
            zonesIds.push(allZones[i].zone_id);
          }
        } else {
          // console.log("entro en el else ")
          for (var i = 0; i < this.user.zones.length; ++i) {
            zonesIds.push(
              allZones.find((x) => x.company_zone_name === this.user.zones[i])
                .zone_id
            );
          }
        }
      }
      const payload = {
        user_area: this.user.area,
        user_contact_email: this.user.contactEmail,
        user_first_name: this.user.firstName,
        user_last_name: this.user.lastName,
        user_login_email: this.user.loginEmail,
        user_phone_number: this.user.phone,
        user_type: this.user.userType === "Admin" ? "ADMIN" : "RECRUITER",
        user_zones: zonesIds,
        user_password: this.user.password,
      };
      // console.log(payload, "this is a payload")
      this.registerUser(payload);
      this.reset();
    },
    reset() {
      this.$refs.formCreateUser.reset();
    },
  },
  computed: {
    ...mapGetters(["getZones"]),
    ...mapState({
      userState: (state) => state.auth.status,
    }),
    userStateLoading() {
      return this.userState === ENUM.LOADING;
    },
    zones() {
      const allZones = this.getZones;
      let newZones = [];
      allZones.map((item)=>{
        if(item.zone_is_active === true && item.zone_is_archive === false){
          newZones.push(item.company_zone_name)
        }
      })
      return newZones
      // return newZones;
    },
  },
  created() {
    this.requestZonesByCompanyId();
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}
</style>
