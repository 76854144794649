<template>
  <v-card class="pr-5 pl-10" elevation="0">
      <v-breadcrumbs
      class="ml-10 ma-0 pa-0 mb-5"
      :items="userLinks"
      divider=">"
      large
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          <p class="caption ma-0" style="color: #8739eb; text-decoration: none">
            {{ item.text }}
          </p>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
 <v-card-title class="ml-8 ma-0 pa-0">
      <div class="d-flex">
        <v-btn icon @click="close"><v-icon small>mdi-arrow-left</v-icon></v-btn>
        <div class="d-flex flex-column align-start mt-1">
          <p class="text-body-1 ma-0 font-weight-bold">Nuevo usuario</p>
          <div class="d-flex align-center">
            <p class="text-caption ma-0 pa-0">Departamento</p>
            <v-divider class="mx-2" vertical></v-divider>
            <div class="d-flex align-center">
              <v-icon small color="#A3A1FB" class="mr-1"
                >mdi-map-marker-outline</v-icon
              >
              <p class="text-caption ma-0">Dirección de la oferta laboral</p>
            </div>
            <div class="d-flex ml-16">
              <div class="d-flex align-center mr-10">
                <p
                  class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded mr-2"
                  style="background: #f2f4f7; color: #a4afc0"
                >
                  00
                </p>
                <p class="text-caption ma-0 font-weight-bold">vacantes</p>
              </div>
              <div class="d-flex align-center">
                <p
                  class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded mr-2"
                  style="background: #e5e7fb; color: #a3a1fb"
                >
                  00
                </p>
                <p class="text-caption ma-0 font-weight-bold">cubiertas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-title>
      <RegisterForm />
  </v-card>
</template>

<script>
import ReturnPage from "../../mixins/ReturnPage"
import RegisterForm from "../../components/Auth/Register"

export default {
  mixins: [ReturnPage],
  components: {RegisterForm},
 data: () => ({
userLinks: [
      {
        text: "Usuarios",
        disabled: false,
        href: "/users",
      },
      {
        text: "Nuevo usuario",
        disabled: true,
      },
    ],
 }),
}
</script>

<style>

</style>